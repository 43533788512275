import React, { useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import BookOpenIcon from '@atlaskit/icon-lab/core/book-open';
import { Box, xcss } from '@atlaskit/primitives';

import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { READ_TIME_METRIC } from './perf.config';

export const i18n = defineMessages({
	abbreviatedReadTime: {
		id: 'read-time.by-line.abbreviated-estimated-read-time',
		defaultMessage: '{readTime} min',
		description: 'Abbreviated estimated time to read this page',
	},
	readTime: {
		id: 'read-time.by-line.estimated-read-time',
		defaultMessage: '{readTime} min read',
		description: 'Estimated time to read this page',
	},
	iconLabel: {
		id: 'read-time.by-line.icon-label',
		defaultMessage: 'Read time',
		description: 'Accessibility label for the book icon next to the read time in the byline',
	},
	tooltipDescription: {
		id: 'read-time.tooltip-description',
		defaultMessage: 'Estimated read time',
		description: 'Tooltip message',
	},
});

const abbreviatedReadTimeStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
});

const readTimeWithBulletStyles = xcss({
	display: 'inline-flex',
});

const baseStyledReadTime = css({
	marginRight: token('space.050', '4px'),
	paddingLeft: token('space.075', '6px'),
	color: token('color.text.subtle', N500),
});

const readTimeBullet = css({
	'&::before': {
		content: '•',
		display: 'inline-block',
		color: token('color.text.subtle', N500),
		paddingRight: token('space.100', '8px'),
		textDecoration: 'none',
	},
});

type ReadTimeComponentType = {
	pageId: string;
	readTime: number | null;
	isAbbreviatedReadTime?: boolean;
	isInEditor?: boolean;
};

export function ReadTimeComponent({
	pageId,
	readTime,
	isAbbreviatedReadTime,
	isInEditor,
}: ReadTimeComponentType) {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const fireTooltipEvent = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'displayed',
				actionSubject: 'tooltip',
				actionSubjectId: 'estimatedReadTime',
				attributes: {
					isInEditor: !!isInEditor,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isInEditor]);

	useEffect(() => {
		if (readTime) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluence-frontend',
					action: 'displayed',
					actionSubject: 'readTime',
					actionSubjectId: pageId,
					attributes: {
						readTime,
						isInEditor: !!isInEditor,
					},
				},
			}).fire();
		}
	}, [pageId, readTime, createAnalyticsEvent, isInEditor]);

	const readTimeMessage = isAbbreviatedReadTime ? i18n.abbreviatedReadTime : i18n.readTime;

	return (
		// styled elements cannot be the primary component as ssr-placeholder attributes
		// will erroneously be added to the <style> componens during SSR. To fix this,
		// this component is wrapped in a div with an inline style
		<div
			data-vc="read-time"
			{...ssrPlaceholderIdProp}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'inline-flex' }}
		>
			<span css={[baseStyledReadTime, !isAbbreviatedReadTime && readTimeBullet]}>
				<Tooltip
					tag="span"
					content={<FormattedMessage {...i18n.tooltipDescription} />}
					onShow={fireTooltipEvent}
				>
					<Box
						xcss={[isAbbreviatedReadTime ? abbreviatedReadTimeStyles : readTimeWithBulletStyles]}
					>
						{isAbbreviatedReadTime && <BookOpenIcon label={formatMessage(i18n.iconLabel)} />}
						<FormattedMessage {...readTimeMessage} values={{ readTime }} />
					</Box>
				</Tooltip>
			</span>
			<PageSegmentLoadEnd metric={READ_TIME_METRIC} />
		</div>
	);
}
