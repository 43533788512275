import React, { type FC } from 'react';

import type { ExtractPresetAPI } from '@atlaskit/editor-common/preset';
import type { useUniversalPreset } from '@atlaskit/editor-core/preset-universal';

import { getTranslation } from '@confluence/i18n';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { fg } from '@confluence/feature-gating';

import { READ_TIME_METRIC } from './perf.config';
import { EditorReadTime } from './EditorReadTime';
import { OriginalReadTime } from './OriginalReadTime';
import { SmartsReadTime } from './SmartsReadTime';

export type FullPageEditorPresetAPI = ExtractPresetAPI<ReturnType<typeof useUniversalPreset>>;

type ReadTimeProps = {
	contentId: string;
	editorApi?: FullPageEditorPresetAPI | null;
	isInEditor?: boolean;
};

export const ReadTimeLoader: FC<ReadTimeProps> = ({ contentId, editorApi, isInEditor }) => {
	return (
		<>
			<PageSegmentLoadStart metric={READ_TIME_METRIC} />
			<ReadTime contentId={contentId} editorApi={editorApi} isInEditor={isInEditor} />
		</>
	);
};

export const ReadTime: FC<ReadTimeProps> = ({ contentId, editorApi, isInEditor }) => {
	const { locale } = getTranslation();
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	if (!shouldRenderReadTime) {
		return null;
	}

	if (isInEditor) {
		return <EditorReadTime contentId={contentId} editorApi={editorApi} />;
	}

	if (fg('confluence_frontend_skip_smarts_read_time')) {
		return <OriginalReadTime contentId={contentId} />;
	}

	return <SmartsReadTime contentId={contentId} />;
};
