import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ErrorDisplay, isStatusCodeError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { expValEquals } from '@confluence/feature-experiments';

import { ReadTimeComponent } from './ReadTimeComponent';
import { ReadTimeSmartsQuery } from './ReadTimeSmartsQuery.graphql';
import { OriginalReadTime } from './OriginalReadTime';

export const SmartsReadTime = ({ contentId }: { contentId: string }) => {
	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeSmartsQuery,
		{
			variables: { id: contentId },
			errorPolicy: 'all',
			fetchPolicy: 'cache-first',
		},
	);

	const readTime = data?.getSmartContentFeature?.readTime;

	if (loading) {
		return null;
	}

	if (readTime == undefined || error) {
		if (isStatusCodeError(error, '403') || error?.message.includes('403')) {
			markErrorAsHandled(error);
			return null;
		}

		return (
			<Fragment>
				<OriginalReadTime contentId={contentId} />
				{error && <ErrorDisplay error={error} />}
			</Fragment>
		);
	}

	return (
		<ReadTimeComponent
			readTime={readTime}
			pageId={contentId}
			isAbbreviatedReadTime={expValEquals(
				'cc-page-experiences-new-renderer-byline',
				'cohort',
				'test',
			)}
		/>
	);
};
