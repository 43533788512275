import React, { memo, useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import debounce from 'lodash/debounce';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

import { useDocChangeContext } from '@confluence/editor-features';
import { ErrorDisplay } from '@confluence/error-boundary';

import { ReadTimeQuery } from './ReadTimeQuery.graphql';
import { extractContentFeatures } from './featuresExtractor';
import { ReadTimeComponent } from './ReadTimeComponent';
import {
	calculateEstimatedReadTime,
	getAdf,
	getContentFeatureExtractedEventObject,
} from './readTimeUtils';
import type { FullPageEditorPresetAPI } from './ReadTime';

const MIN_WORD_COUNT = 150;

type EditorReadTimeProps = {
	contentId: string;
	editorApi?: FullPageEditorPresetAPI | null;
};

const editorReadTimeStyles = xcss({
	marginRight: 'space.100',
	marginTop: 'space.050',
});

const calculateEditorReadTime = (adf?: JSONDocNode | null): number | null => {
	if (adf) {
		const features = extractContentFeatures(adf);
		if (!features.wordCount || features.wordCount < MIN_WORD_COUNT) {
			return null; // Don't show the read time for live pages with little content
		}
		return calculateEstimatedReadTime(features);
	}
	return null;
};

/**
 * Read Time component with support for automatically recalculating and updating read time after a document change in the editor.
 */
export const EditorReadTime: FC<EditorReadTimeProps> = memo(
	({ contentId, editorApi }: EditorReadTimeProps) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { subscribeDocChange, unsubscribeDocChange } = useDocChangeContext();

		const { data, loading, error } = useQuery(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			ReadTimeQuery,
			{
				variables: { contentId },
				fetchPolicy: 'cache-first',
			},
		);

		const adf = useMemo(() => getAdf(data), [data]);

		// Initialize readtime with preloaded data for SSR
		const [readTime, setReadTime] = useState<number | null>(calculateEditorReadTime(adf));

		useEffect(() => {
			if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
				return;
			}
			const contentType = data?.content?.nodes[0]?.type;
			createAnalyticsEvent(
				getContentFeatureExtractedEventObject({ contentType, contentId, adf, isInEditor: true }),
			).fire();
		}, [contentId, loading, adf, data?.content?.nodes, error, createAnalyticsEvent]);

		useEffect(() => {
			if (adf && !loading && !error) {
				setReadTime(calculateEditorReadTime(adf));
			}
		}, [adf, loading, error]);

		const setReadTimeFromEditorState = useCallback(async () => {
			if (editorApi) {
				editorApi.core?.actions.requestDocument((doc) => {
					setReadTime(calculateEditorReadTime(doc));
				});
			}
		}, [editorApi, setReadTime]);

		useEffect(() => {
			// Sometimes the ADF from the query is outdated if the byline is re-rendered quickly after a document change
			// So recalculate read time from the editor ADF when the editor is ready
			void setReadTimeFromEditorState();
		}, [setReadTimeFromEditorState]);

		const debouncedCalculateReadTime = useMemo(
			() => debounce(setReadTimeFromEditorState, 5000),
			[setReadTimeFromEditorState],
		);

		useEffect(() => {
			subscribeDocChange(debouncedCalculateReadTime);
			return () => unsubscribeDocChange(debouncedCalculateReadTime);
		}, [debouncedCalculateReadTime, subscribeDocChange, unsubscribeDocChange]);

		if (error) {
			return <ErrorDisplay error={error} />;
		}

		if (readTime) {
			return (
				<Box xcss={editorReadTimeStyles} testId="editor-read-time">
					<ReadTimeComponent
						readTime={readTime}
						pageId={contentId}
						isAbbreviatedReadTime
						isInEditor
					/>
				</Box>
			);
		}

		return null;
	},
);
