import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay } from '@confluence/error-boundary';
import { expValEquals } from '@confluence/feature-experiments';

import { ReadTimeComponent } from './ReadTimeComponent';
import { extractContentFeatures } from './featuresExtractor';
import { ReadTimeQuery } from './ReadTimeQuery.graphql';
import {
	calculateEstimatedReadTime,
	getAdf,
	getContentFeatureExtractedEventObject,
} from './readTimeUtils';

export const OriginalReadTime = ({ contentId }: { contentId: string }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeQuery,
		{
			variables: { contentId },
			fetchPolicy: 'cache-only',
		},
	);

	useEffect(() => {
		const adf = getAdf(data);
		if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
			return;
		}
		const contentType = data?.content?.nodes?.[0]?.type;
		createAnalyticsEvent(
			getContentFeatureExtractedEventObject({ contentType, contentId, adf, isInEditor: false }),
		).fire();
	}, [contentId, loading, data, error, createAnalyticsEvent]);

	const readTime = useMemo(() => {
		const adf = getAdf(data);
		if (!adf || loading || error || !contentId) {
			return null;
		}
		const features = extractContentFeatures(adf);

		return calculateEstimatedReadTime(features);
	}, [loading, error, data, contentId]);

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	if (readTime) {
		return (
			<ReadTimeComponent
				readTime={readTime}
				pageId={contentId}
				isAbbreviatedReadTime={expValEquals(
					'cc-page-experiences-new-renderer-byline',
					'cohort',
					'test',
				)}
			/>
		);
	}

	return null;
};
